import React from "react";
import { useNavigate } from "react-router";

import { LogoContainer } from "./logo-contanier.styles";

const LogoContanierComponent = ({ logoUrl, hidden, url = "#" }) => {
  const navigate = useNavigate();
  return (
    <LogoContainer hidden={hidden} onClick={() => navigate(url)} url={url}>
      <img style={{ width: "13em" }} src={logoUrl} alt={"site logo"} />
    </LogoContainer>
  );
};

export default LogoContanierComponent;
